import $ from 'jquery';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend/i18nextHttpBackend.min';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import jqueryI18next from 'jquery-i18next';
import 'bootstrap';
import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/additional-methods';
import helpers from './helpers';
import validation from './validations';

const { goToSection, submitHandler, setLocale } = helpers;

$.ajaxSetup({
  beforeSend(xhr, options) {
    options.url = process.env.BASE_URL + options.url;
  },
});

// Form validation
function setFormValidation() {
  const { settings, rules, messages } = validation(i18next);

  $('form.request').each(function formValidate() {
    $.validator.setDefaults(settings);
    $(this).validate({
      rules,
      messages,
      submitHandler,
    });
  });
}

$(() => {
  const hours = new Date().getHours();
  const isNight = (hours >= 19 && hours < 24) || (hours >= 0 && hours < 6);
  let winWidth = $(window).width();

  if (isNight) {
    $('html').addClass('dark');
  }

  i18next
    .use(HttpApi)
    .use(i18nextBrowserLanguageDetector)
    .init({
      debug: false,
      fallbackLng: ['es', 'en', 'hi'],
      lowerCaseLng: true,
      load: 'languageOnly',
      backend: {
        loadPath: `${process.env.NODE_ENV === 'production' ? '' : '/src'}/locales/{{lng}}.json`,
        crossDomain: false,
      },
    })
    .then(() => {
      const getCurrentLng = () => (i18next.language ? i18next.language.split('-')[0] : i18next.language) || window.localStorage.i18nextLng || '';

      $('body').localize().removeClass('hidden').addClass(getCurrentLng());

      setFormValidation();

      $('.lang-selector a').on('click', (e) => {
        const $this = $(e.currentTarget);
        const video = document.querySelectorAll('video');

        $('.selected-lang').html(`<img src="${$this.find('img').attr('src')}" />`);

        setLocale($this.attr('data-lang'));
        $('body').removeClass('es en hi').addClass(getCurrentLng());

        if (video.length > 0) {
          video.forEach((v) => {
            v.currentTime = 0;
            v.pause();
          });
        }
      });
      $(`.lang-selector a[data-lang="${getCurrentLng()}"]`).trigger('click');
    });

  jqueryI18next.init(i18next, $, {
    tName: 't',
    i18nName: 'i18n',
    handleName: 'localize',
    selectorAttr: 'data-i18n',
    targetAttr: 'i18n-target',
    optionsAttr: 'i18n-options',
    useOptionsAttr: false,
    parseDefaultValueFromContent: true,
  });

  $(window).resize((e) => {
    const $this = $(e.currentTarget);

    if ($this.width() !== winWidth) {
      $('.header').removeClass('collapsed');
      $('.navbar-collapse.collapse').removeClass('show');
      $('.header .nav-item').removeClass('show');
      winWidth = $this.width();
    }
  });

  // File input
  $('.custom-file-input').on('change', (e) => {
    const $this = $(e.currentTarget);
    const fileName = $this.val().split('\\').pop();

    $this.siblings('.custom-file-label').addClass('selected').html(fileName);
  });

  // Go to section
  $('.go-to').on('click', (e) => {
    const $this = $(e.currentTarget);
    const section = $this.attr('data-section');
    const offset = $this.attr('data-offset') || 40;

    e.preventDefault();
    goToSection($(section), offset);
  });

  // Header
  $('.header .navbar-toggler').on('click', (e) => {
    const $this = $(e.currentTarget);
    const header = $this.parents('.header');

    if (!header.hasClass('is-collapsing')) {
      header.addClass('is-collapsing');

      if (header.hasClass('collapsed')) {
        header.removeClass('collapsed');
      } else {
        header.addClass('collapsed');
      }

      setTimeout(() => {
        header.removeClass('is-collapsing');
      }, 360);
    }
  });
});
